import React from 'react'
import { Provider, Heading, Subhead } from 'rebass'
import {
  Hero, CallToAction, ScrollDownIndicator
} from 'react-landing-page'
import HeroComponent from '../components/HeroComponent'
import FeatureComponent from '../components/FeatureComponent'
import TestimonialComponent from '../components/TestimonialComponent'
import ChecklistComponent from '../components/ChecklistComponent'
import FooterComponent from '../components/FooterComponent'
import VideoComponent from '../components/VideoComponent'
import PricingComponent from '../components/PricingComponent'
import HeaderComponent from '../components/HeaderComponent'
import TeamComponent from '../components/TeamComponent'


const AppWrapper = () => {


    return (
        <Provider>
            <HeaderComponent />
            <HeroComponent />
            {/* <VideoComponent /> */}
            <FeatureComponent />
            <ChecklistComponent />
            <TestimonialComponent />
            <PricingComponent />
            <TeamComponent />
            <FooterComponent />
        </Provider>
      )
}


export default AppWrapper