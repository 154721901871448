import React from 'react'

import { Flex, Box } from 'rebass'
import LOGO from '../../assets/dthq_logo.png'

const HeaderComponent = () => {

    return (
        <Flex alignItems="center" style={{position: 'absolute'}}>
            <Box
                mr='auto'
                ml='3'
                mt='3'
                width={[
                    2/3,
                    3/4,
                    1/2,
                    1/2
                ]} >
                <img src={LOGO} height="60" />
            </Box>
        </Flex>
    )
}

export default HeaderComponent

