import React from 'react'
import { Heading, Flex, Box } from 'rebass'
import {
  Feature
} from 'react-landing-page'
import FlexContainer from '../FlexContainer'

const FeatureComponent = () => {



    const features = [
        {
            icon: "💻",
            description: "Learn the knowledge for app development",
            title: "Learn"
        },
        {
            icon: "👩‍💻",
            description: "Hone your app making skills",
            title: "Train"
        },
        {
            icon: "🏆",
            description: "Head-to-head app development competitions",
            title: "Compete"
        },

    ]

    return (
        <FlexContainer render={() => 
            <>
                <Heading mb={4}  textAlign="center">What is it?</Heading>

                <Flex flexWrap="wrap" justifyContent="center" width={['100%']} >
                    { features && features.map( f => 
                        <Box justifyContent="center" width={[ '100%', '50%', '33%' ]} >

                            <Feature 
                                width={['100%']}
                                className="feature-item" 
                                icon={f.icon} 
                                description={f.description} 
                                >
                                {f.title}
                            </Feature>
                        </Box>
                    )}
                </Flex>

            </>
        } />
      )
}


export default FeatureComponent