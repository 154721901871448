import React from 'react'
import { Heading } from 'rebass'
import {
    Checklist
} from 'react-landing-page'
import FlexContainer from '../FlexContainer'

const ChecklistComponent = () => {

    return (
        <FlexContainer
            render={() => 
                <>
                    <Heading mb={4} >Why pick DevTeam HQ?</Heading>
                    <Checklist children={[
                        'Learn to make apps',
                        'Compete for prizes',
                        'No experience required',
                    ]}/>
                </>
            }/>
    )
}


export default ChecklistComponent