import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppWrapper from './wrapper'

function App() {
  return <AppWrapper />
}

export default App;
