import React from 'react'
import { Heading, Text, Flex } from 'rebass'
import {
    Contributor
} from 'react-landing-page'
import FlexContainer from '../FlexContainer'


const TeamComponent = () => {

    return (
        <FlexContainer
            render={() => 
                <>
                    <Heading textAlign="center" mb={4} >Made by</Heading>
                    <Flex justifyContent="space-around">
                        <Contributor
                            fullName="Alternative Education"
                            title="501(c)(3) nonprofit organization"
                            avatar="https://devteamhq.org/mage.png"
                            >
                            <Text textAlign="center">
                                We are a team of software professionals dedicated to providing an educational and fun learning 
                                platform for students to learn professional skills. We hope to demystify the application development 
                                process thus lowering the barrier of entry for the youth to start making applications.
                            </Text>


                            {/* <Flex>
                                <NavLink href='https://github.com/'>Make a tax-deductible donation</NavLink>
                            </Flex> */}

                        </Contributor>
                    </Flex>                
                </>
            } />
      )
}

export default TeamComponent


