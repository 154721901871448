import React from 'react'
import { Heading, Flex, Box } from 'rebass'
import {
  PricingTier, CallToAction
} from 'react-landing-page'
import { APP_SIGNUP } from '../../shared'

const PricingComponent = () => {
    
    return (
        <Box  m={5} >
            <Heading mb={4} textAlign="center">Pricing</Heading>
            <Flex justifyContent="space-around">

                <PricingTier bg="silver"
                    tierName="Everyone"
                    price="Free"
                    billingType="/ forever"
                    sellingPoints={[
                        '🔥 Full feature set',
                        '💎 Gamified experience',
                        '😌 Future updates',
                        '📲 Mobile browser'
                    ]}>
                    <CallToAction bg="black" width={1} href={APP_SIGNUP} >Sign Up</CallToAction>
                </PricingTier>

            </Flex>
        </Box>


      )
}


export default PricingComponent