import React from 'react'
import { Heading, Flex } from 'rebass'
import {
  Testimony
} from 'react-landing-page'
import FlexContainer from '../FlexContainer'


const TestimonialComponent = () => {

    return (
        <FlexContainer
            render={() => 
                <>
                    <Heading mb={4}  textAlign="center">What people say</Heading>
                    <Flex alignItems="flex-end" justifyContent="space-around">

                        <Testimony
                            authorAvatar="https://devteamhq.org/elf.png"
                            authorName="Oli, 13"
                            authorTitle="developer">
                                After a week of using DevTeam HQ I was able to create my very own application, and it was fun!
                        </Testimony>
                    </Flex>
                </>
            } />
      )
}

export default TestimonialComponent
