import React from 'react'
import { Flex, NavLink, Small } from 'rebass'
import { APP_SIGNUP } from '../../shared'

const FooterComponent = () => {


    return (
        <Flex is="footer" alignItems="center" p={3}>
            <NavLink children="Sign Up" href={APP_SIGNUP} />
            <Small color="grey" ml="auto">© DevTeamHQ { new Date().getFullYear() }</Small>
        </Flex>

      )
}


export default FooterComponent