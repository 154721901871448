import React from 'react'
import { Flex, Box } from 'rebass'


const FlexContainer = ({render}) => {


    return (
        <Flex  alignItems="center">
            <Box
                mt="6"
                mb="6"
                mr='auto'
                ml='auto'
                width={[
                    2/3,
                    3/4,
                    1/2,
                    1/2
                ]} >
                { render() }
            </Box>
        </Flex>
      )
}


export default FlexContainer