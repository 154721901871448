import React from 'react'
import { Heading, Subhead, Flex, Box } from 'rebass'
import {
  Hero, CallToAction, ScrollDownIndicator, MacWindow, Phone
} from 'react-landing-page'
import MobileScreenshot from '../../assets/mobile_187x406.png'
import DesktopScreenshot from '../../assets/desktop_640x480.png'
import { APP_LAUCN } from '../../shared'

const HeroComponent = () => {


    return (
        <Hero
            bg='white'
            bgOpacity={0.5}
            backgroundColor='white'
        >
            <Flex flexWrap='wrap' alignItems='center'>
                <Flex alignItems='flex-start' width={[1, 1, 1 / 2]} p={3}>
                    <MacWindow style={{transform: 'translate(32px, 0px)'}}
                        src={DesktopScreenshot} />
                    <Phone color='black'
                        style={{transform: 'translate(-32px, 32px)'}}
                        src={MobileScreenshot} />
                </Flex>
                <Box width={[1, 1, 1 / 2]} p={3} >
                    <Heading textAlign='center'>Learn to Make Apps</Heading>
                    <Subhead textAlign='center'>No Experience Required</Subhead>
                    <Flex mt={3} flexWrap='wrap' justifyContent='center'>
                        <CallToAction
                            style={{backgroundColor: "#1FCC9D"}}
                            href={APP_LAUCN}
                            mr={3}
                        >Launch App</CallToAction>
                    </Flex>
                </Box>
            </Flex>
            <ScrollDownIndicator />
        </Hero>
      )
}


export default HeroComponent